import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SeoService } from '../../serives/seo/seo.service';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css']
})
export class GeneralComponent implements OnInit {

  titulo = 'Precio Formulario Etias | Requisitos para Europa';
  descripcion = 'El formulario ETIAS es el nuevo requisito para poder visitar el continente Europeo, conoce en qué consiste y el valor para realizar este trámite migratorio';
  keysWords = 'ETIAS, requisitos europa, Requisitos Schengen, visa a Europa, visa schengen. visado etias, formulario Etias, valor etias,cuanto cuesta el etias, precio etias';

  constructor(private titleService: Title, private seo: SeoService) { 
    titleService.setTitle(this.titulo);
  }

  ngOnInit() {
    this.seo.createCanonicalURL();
    this.seo.createDescription(this.descripcion);
    this.seo.createOpenGraph(this.titulo, this.descripcion);
    this.seo.createKeyWords(this.keysWords);
  }

}
