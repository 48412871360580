import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment  } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private http: HttpClient) { }

  sendEmail(data) {
    // return this.http.post('http://localhost/backends/etias/Correo/send', JSON.stringify(data));
    return this.http.post(`${environment.serve}/Correo/send`, JSON.stringify(data));
  }

  sendEmailUpdates(data) {
    return this.http.post(`${environment.serve}/Correo/sendUpdates`, JSON.stringify(data));
  }
}


