import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from "@angular/common";
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(@Inject(DOCUMENT) private dom) { }

  createCanonicalURL() {
		$('link[rel=canonical]').remove();
    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', this.dom.URL);
  }

  createDescription(descripcion: string){
    $( ".descri" ).remove();
    var desc = this.dom.createElement('meta');
    desc.setAttribute('name', 'description');
    desc.setAttribute('class', 'descri');
    desc.content = descripcion;
    this.dom.getElementsByTagName('head')[0].appendChild(desc);
  }

  createKeyWords(keywords: string){
    $( ".keys" ).remove();
    var keyword = this.dom.createElement('meta');
    keyword.setAttribute('name', 'keywords');
    keyword.setAttribute('class', 'keys');
    keyword.content = keywords;
    this.dom.getElementsByTagName('head')[0].appendChild(keyword);
  }

  createOpenGraph(tilePag: string, descripcion: string){
    $( ".twitter" ).remove();
    $( ".facebook" ).remove();

    //Open Graph Facebook
    let url = this.dom.createElement('meta');
    url.setAttribute('property', 'og:url');
    url.setAttribute('class', 'facebook');
    url.content = this.dom.location;
    this.dom.getElementsByTagName('head')[0].appendChild(url);

    let title = this.dom.createElement('meta');
    title.setAttribute('property', 'og:title');
    title.setAttribute('class', 'facebook');
    url.setAttribute('class', 'facebook');
    title.content = tilePag;
    this.dom.getElementsByTagName('head')[0].appendChild(title);

    let tipe = this.dom.createElement('meta');
    tipe.setAttribute('property', 'og:type');
    title.setAttribute('class', 'facebook');
    tipe.content = 'Sitio Web';
    this.dom.getElementsByTagName('head')[0].appendChild(tipe);

    let image = this.dom.createElement('meta');
    image.setAttribute('property', 'og:image');
    image.setAttribute('class', 'facebook');
    image.content = './assets/etias-logo.png';
    this.dom.getElementsByTagName('head')[0].appendChild(image);

    let nameSite = this.dom.createElement('meta');
    nameSite.setAttribute('property', 'og:site_name');
    nameSite.setAttribute('class', 'facebook');
    nameSite.content = tilePag;
    this.dom.getElementsByTagName('head')[0].appendChild(nameSite);

    let desc = this.dom.createElement('meta');
    desc.setAttribute('property', 'og:description');
    desc.setAttribute('class', 'facebook');
    desc.content = descripcion;
    this.dom.getElementsByTagName('head')[0].appendChild(desc);

   //Open Graph Twitter
    let site = this.dom.createElement('meta');
    site.setAttribute('name', 'twitter:site');
    site.setAttribute('class', 'twitter');
    site.content = '@SeguroParaViaje';
    this.dom.getElementsByTagName('head')[0].appendChild(site);

    let creator = this.dom.createElement('meta');
    creator.setAttribute('name', 'twitter:creator');
    creator.setAttribute('class', 'twitter');
    creator.content = '@SeguroParaViaje';
    this.dom.getElementsByTagName('head')[0].appendChild(creator);

    let imgT = this.dom.createElement('meta');
    imgT.setAttribute('name', 'twitter:image');
    imgT.setAttribute('class', 'twitter');
    imgT.content = './assets/etias-logo.png';
    this.dom.getElementsByTagName('head')[0].appendChild(imgT);

    let card = this.dom.createElement('meta');
    card.setAttribute('name', 'twitter:card');
    card.setAttribute('class', 'twitter');
    card.content = 'Sitio Web';
    this.dom.getElementsByTagName('head')[0].appendChild(card);

    let titleT = this.dom.createElement('meta');
    titleT.setAttribute('name', 'twitter:title');
    titleT.setAttribute('class', 'twitter');
    titleT.content = tilePag;
    this.dom.getElementsByTagName('head')[0].appendChild(titleT);

    let descT = this.dom.createElement('meta');
    descT.setAttribute('name', 'twitter:description');
    descT.setAttribute('class', 'twitter');
    descT.content = descripcion;
    this.dom.getElementsByTagName('head')[0].appendChild(descT);

    let urlT = this.dom.createElement('meta');
    urlT.setAttribute('name', 'twitter:domain');
    urlT.setAttribute('class', 'twitter');
    urlT.content = this.dom.location;
    this.dom.getElementsByTagName('head')[0].appendChild(urlT);
  }

  createDatosEstructuradosFAQ(){
    $( ".datoStructfaq" ).remove();
    let script = this.dom.createElement('script');
    script.setAttribute('class', 'datoStructfaq');
    script.type = `application/ld+json`;
    script.text = `
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "¿Cuáles son los beneficios del ETIAS?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>El principal beneficio que brinda el ETIAS, es contribuir con la seguridad de la Unión Europea, proteger el territorio ante posibles viajeros que puedan causar daños en el pais.</p>"
        }
      }, {
        "@type": "Question",
        "name": "¿Cuál es la diferencia entre la Unión Europea y la zona Schengen?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>Su diferencia radica en la unión de países que se han juntado para conformar una Europa libre de conflictos, tal es el caso de la Unión Europea, la cual está conformada por 28 países que se han entrelazado para conformar proyectos políticos, económicos y sociales.</p><p>En cuanto al espacio Schengen hace referencia a un acuerdo entre 26 países que unieron sus fronteras y comparten sus mismas reglas migratorias, el propósito de este acuerdo firmado el 14 de junio de 1985 es brindar un libre movimiento de personas, fortalecer el turismo, la cultura, los servicios y el capital económico.</p>"
        }
      }, {
        "@type": "Question",
        "name": "¿Los ecuatorianos ya no necesitarán visa Schengen?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>Actualmente Ecuador se encuentra en el proceso de exención de visado Schengen, en el momento en que esta eliminación de visa sea un hecho,automáticamente este país entrará en la lista de países que deberán solicitar el requisito ETIAS.</p><p>Sin embargo en la actualidad aún deben tramitar el visado Schengen que consiste en rellenar un formulario de solicitud, contar con el pasaporte al día, presentarse ante el consulado del país a visitar y realizar la entrevista, allí deberás contar con otros requisitos indispensables para completar la solicitud de visa.</p><p>Si aun no sabes cuáles son los requisitos específicos para completar tu proceso de visado, te aconsejo que busques asesoría migratoria. En Visa Travel Center podrás obtener esta información. Recuerda que para viajar al espacio Schengen es obligatorio contar con un seguro de viaje y en cuanto al ETIAS que es un requisito para países exentos de visado Schengen, busca obtener información del turista en cuanto a seguridad y estado de salud, así que en ambos casos es necesario cubrir este requisito.</p><p>Nos tomamos el trabajo de indicarte qué compañías cuentan con las mejores coberturas médicas y asistencias de viaje. En Seguro de Viaje y Seguro Para Viaje existen planes para todo tipo de viajeros que piensan visitar el viejo continente.</p>"
        }
      }, {
        "@type": "Question",
        "name": "Cuando caduque la visa ETIAS, ¿qué debo hacer?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>El permiso tendrá una validez de 3 años máximo, después de esto se deberá solicitar de nuevo el permiso. Recuerda que este solo aplica para estancias cortas de máximo 90 días. En caso de que tu pasaporte caduque durante el período de validez de la exención de visa ETIAS, el viajero deberá solicitar su autorización de viaje y renovar su pasaporte.</p>"
        }
      }]
      }
      `;
    this.dom.getElementsByTagName('head')[0].appendChild(script);
  }

  conversionTagManager(event: string){
    $( ".conversionAseCon" ).remove();
    let script = this.dom.createElement('script');
    script.setAttribute('class', 'conversionAseCon');
    script.type = `text/javascript`;
    script.text = `
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({'event': '`+event+`'});
    `;
    this.dom.getElementsByTagName('head')[0].appendChild(script);
    $( ".conversionAseCon" ).remove();
  }

}
