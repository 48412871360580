import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SeoService } from '../../serives/seo/seo.service';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent implements OnInit {

  titulo = 'Preguntas Frecuentes Visado ETIAS | Requisitos para Europa';
  descripcion = ' Algunos de los beneficios del formulario ETIAS es proteger la seguridad del país y tener control sobre la salud del viajero, por lo que es indispensable contar con una asistencia o seguro médico de viaje. ¡Conoce nuestros planes y cumple con los requisitos migratorios!';
  keysWords = 'ETIAS, Requisitos Schengen, visa a Europa, visa schengen. visado etias, formulario Etias, ecuador visa schengen, requisitos europa';

  constructor(private titleService: Title, private seo: SeoService) { 
    titleService.setTitle(this.titulo);
  }

  ngOnInit() {
    this.seo.createCanonicalURL();
    this.seo.createDescription(this.descripcion);
    this.seo.createOpenGraph(this.titulo, this.descripcion);
    this.seo.createKeyWords(this.keysWords);
    this.seo.createDatosEstructuradosFAQ();
  }

}
