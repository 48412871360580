import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SeoService } from '../../serives/seo/seo.service';

@Component({
  selector: 'app-present',
  templateUrl: './present.component.html',
  styleUrls: ['./present.component.css']
})
export class PresentComponent implements OnInit {

  titulo = 'Etias para menores de edad | Requisitos para Europa';
  descripcion = 'Esto es lo que debes tener en cuenta para solicitar el permiso ETIAS en el caso de los menores de edad o viajeros con doble nacionalidad a partir de 2021.';
  keysWords = 'ETIAS, Requisitos Schengen, visa a Europa, visa schengen, Etias doble nacionalidad, Etias menores de edad, países Etias';

  constructor(private titleService: Title, private seo: SeoService) { 
    titleService.setTitle(this.titulo);
  }

  ngOnInit() {
    this.seo.createCanonicalURL();
    this.seo.createDescription(this.descripcion);
    this.seo.createOpenGraph(this.titulo, this.descripcion);
    this.seo.createKeyWords(this.keysWords);
  }

}
