import { Component, OnInit } from '@angular/core';
import { Updates } from "src/app/models/updates.model";
import { EmailService } from 'src/app/serives/email.service';
import Swal from "sweetalert2";
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-form-update',
  templateUrl: './form-update.component.html',
  styleUrls: ['./form-update.component.css']
})
export class FormUpdateComponent implements OnInit {

  data: Updates;

  constructor(private emailService: EmailService) { 
    this.data = {  name: '', email: ''} 
  }

  ngOnInit() {
  }

  SendUpdate(form: NgForm) {
    if(form.valid != false) {
      // console.log('form valid');
      this.emailService.sendEmailUpdates(this.data).subscribe((resp: any) => {
        console.log(resp)
        if(resp.estado == true){
          Swal.fire(
            'Etias Requerimientos!',
            resp.mensaje,
            'success'
          )
        } else {
          Swal.fire(
            'Etias Requerimientos!',
            'Error al enviar el correo',
            'success'
          )
        }
        form.reset({pristine: false});
      });
    }else {
      console.log('form invalid');
    }
  }

}
