import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SeoService } from '../../serives/seo/seo.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  titulo = 'Etias | Requisito para viajar a Europa';
  descripcion = 'Uno de los requisitos para viajar a Europa desde 2021, es el permiso ETIAS, conoce como solicitarlo y los requerimientos migratorios para visitar territorio Schengen';
  keysWords = 'ETIAS, Requisitos Schengen, visa a Europa, visa schengen. visado etias, formulario Etias, requerimientos migratorios';

  constructor(private titleService: Title, private seo: SeoService) { 
    titleService.setTitle(this.titulo);
  }

  ngOnInit() {
    this.seo.createCanonicalURL();
    this.seo.createDescription(this.descripcion);
    this.seo.createOpenGraph(this.titulo, this.descripcion);
    this.seo.createKeyWords(this.keysWords);
  }

}
