import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  constructor() { }

  latinAmerica = [
    { img: 'assets/requirements/colombia.png', webp: 'assets/requirements/webp/colombia.webp', name: 'Colombia' },
    { img: 'assets/requirements/guatemala.png', webp: 'assets/requirements/webp/guatemala.webp', name: 'Guatemala' },
    { img: 'assets/requirements/mexico.png', webp: 'assets/requirements/webp/mexico.webp', name: 'México' },
    { img: 'assets/requirements/argentina.png', webp: 'assets/requirements/webp/argentina.webp', name: 'Argentina' },
    { img: 'assets/requirements/panama.png', webp: 'assets/requirements/webp/panama.webp', name: 'Panamá' },
    { img: 'assets/requirements/nicaragua.png', webp: 'assets/requirements/webp/nicaragua.webp', name: 'Nicaragua' },
    { img: 'assets/requirements/honduras.png', webp: 'assets/requirements/webp/honduras.webp', name: 'Honduras' },
    { img: 'assets/requirements/chile.png', webp: 'assets/requirements/webp/chile.webp', name: 'Chile' },
    { img: 'assets/requirements/venezuela.png', webp: 'assets/requirements/webp/venezuela.webp', name: 'Venezuela' },
    { img: 'assets/requirements/uruguay.png', webp: 'assets/requirements/webp/uruguay.webp', name: 'Uruguay' },
    { img: 'assets/requirements/el-salvador.png', webp: 'assets/requirements/webp/el-salvador.webp', name: 'Salvador' },
    { img: 'assets/requirements/brasil.png', webp: 'assets/requirements/webp/brasil.webp', name: 'Brasil' },
    { img: 'assets/requirements/peru.png', webp: 'assets/requirements/webp/peru.webp', name: 'Perú' },
    { img: 'assets/requirements/paraguay.png', webp: 'assets/requirements/webp/paraguay.webp', name: 'Paraguay' },
    { img: 'assets/requirements/costa-rica.png', webp: 'assets/requirements/webp/costa-rica.webp', name: 'Costa Rica' }
  ]

  schengen = {
    yes: [
        {img: 'assets/countries/austria.png', webp: 'assets/countries/webp/austria.webp', name: 'Austria'},
        {img: 'assets/countries/belgica.png', webp: 'assets/countries/webp/belgica.webp', name: 'Bélgica'},
        {img: 'assets/countries/luxemburgo.png', webp: 'assets/countries/webp/luxemburgo.webp', name: 'Luxemburgo'},
        {img: 'assets/countries/paises-bajos.png', webp: 'assets/countries/webp/paises-bajos.webp', name: 'Países Bajo'},
        {img: 'assets/countries/alemania.png', webp: 'assets/countries/webp/alemania.webp', name: 'Alemania'},
        {img: 'assets/countries/francia.png', webp: 'assets/countries/webp/francia.webp', name: 'Francia'},
        {img: 'assets/countries/espana.png', webp: 'assets/countries/webp/espana.webp', name: 'España'},
        {img: 'assets/countries/portugal.png', webp: 'assets/countries/webp/portugal.webp', name: 'Portugal'},
        {img: 'assets/countries/suecia.png', webp: 'assets/countries/webp/suecia.webp', name: 'Suecia'},
        {img: 'assets/countries/finland.png', webp: 'assets/countries/webp/finland.webp', name: 'Finlandia'},
        {img: 'assets/countries/dinamarca.png', webp: 'assets/countries/webp/dinamarca.webp', name: 'Dinamarca'},
        {img: 'assets/countries/lituania.png', webp: 'assets/countries/webp/lituania.webp', name: 'Lituania'},
        {img: 'assets/countries/estonia.png', webp: 'assets/countries/webp/estonia.webp', name: 'Estonia'},
        {img: 'assets/countries/polonia.png', webp: 'assets/countries/webp/polonia.webp', name: 'Polonia'},
        {img: 'assets/countries/eslovaquia.png', webp: 'assets/countries/webp/eslovaquia.webp', name: 'Eslovaquia'},
        {img: 'assets/countries/hungria.png', webp: 'assets/countries/webp/hungria.webp', name: 'Hungría'},
        {img: 'assets/countries/eslovenia.png', webp: 'assets/countries/webp/eslovenia.webp', name: 'Eslovenia'},
        {img: 'assets/countries/italia.png', webp: 'assets/countries/webp/italia.webp', name: 'Italia'},
        {img: 'assets/countries/grecia.png', webp: 'assets/countries/webp/grecia.webp', name: 'Grecia'},
        {img: 'assets/countries/rep-checa.png', webp: 'assets/countries/webp/rep-checa.webp', name: 'Rep. Checa'},
        {img: 'assets/countries/malta.png', webp: 'assets/countries/webp/malta.webp', name: 'Malta'},
    ],
    not: [
        {img: 'assets/countries/islandia.png', webp: 'assets/countries/webp/islandia.webp', name: 'Islandia'},
        {img: 'assets/countries/noruega.png', webp: 'assets/countries/webp/noruega.webp', name: 'Noruega'},
        {img: 'assets/countries/monaco.png', webp: 'assets/countries/webp/monaco.webp', name: 'Mónaco'},
        {img: 'assets/countries/liechtenstein.png', webp: 'assets/countries/webp/liechtenstein.webp', name: 'Liechtenstein'},
        {img: 'assets/countries/vaticano.png', webp: 'assets/countries/webp/vaticano.webp', name: 'Vaticano'},
        {img: 'assets/countries/suiza.png', webp: 'assets/countries/webp/suiza.webp', name: 'Suiza'},
        {img: 'assets/countries/san-marino.png', webp: 'assets/countries/webp/san-marino.webp', name: 'San Marin'},
      ]
    }


  getCountriesLatinAmerica() {
    return this.latinAmerica;
  }

  getCountriesSchengen() {
    return this.schengen;
  }

}
