import { Component, OnInit } from '@angular/core';
import { CountriesService } from 'src/app/serives/countries.service';
import { Title } from '@angular/platform-browser';
import { SeoService } from '../../serives/seo/seo.service';

@Component({
  selector: 'app-requirements',
  templateUrl: './requirements.component.html',
  styleUrls: ['./requirements.component.css']
})
export class RequirementsComponent implements OnInit {

  titulo = 'Requisitos para solicitar Etias | Requisitos para Europa';
  descripcion = 'Para realizar el trámite de ETIAS y visitar Europa, debes cumplir con una serie de requisitos y completar un formulario online';
  keysWords = 'ETIAS, Trámite Etias, requisitos a Europa, solicitud Etias, visado etias, formulario Etias, formulario online Etias';

  selectCountrieValue: string;
  countries = [];


  constructor(private countriesService: CountriesService,private titleService: Title, private seo: SeoService ) {
    titleService.setTitle(this.titulo);
   }

  ngOnInit() {
    this.seo.createCanonicalURL();
    this.seo.createDescription(this.descripcion);
    this.seo.createOpenGraph(this.titulo, this.descripcion);
    this.seo.createKeyWords(this.keysWords);
    this.selectCountrieValue = '1';
    this.countries = this.countriesService.getCountriesLatinAmerica();
  }

  selectChange(data) {
    console.log(data);
    this.selectCountrieValue = data;
  }
}
