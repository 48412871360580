import { Component, OnInit, OnDestroy } from '@angular/core';
import { Email } from 'src/app/models/email.model';
import { EmailService } from 'src/app/serives/email.service';
import Swal from "sweetalert2";
import { NgForm } from '@angular/forms';
import { SeoService } from 'src/app/serives/seo/seo.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-form-home',
  templateUrl: './form-home.component.html',
  styleUrls: ['./form-home.component.css']
})
export class FormHomeComponent implements OnInit, OnDestroy {

  data:Email;

  private unsubscribe$ = new Subject<void>();

  constructor(private emailService: EmailService, private seoService: SeoService) { 
    this.data = {  name: '', origin: '', email: '', phone: '' } 
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
 
 send(form: NgForm) {
    console.log(form);
    if(form.valid != false) {
      // console.log('form valid');
      this.seoService.conversionTagManager('pq0fCKeikboBEIf_-e4D');
      this.emailService.sendEmail(this.data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp: any) => {
        console.log(resp)
        if(resp.estado == true){
          Swal.fire(
            'Etias Requerimientos!',
            resp.mensaje,
            'success'
          )
        } else {
          Swal.fire(
            'Etias Requerimientos!',
            'Error al enviar el correo',
            'success'
          )
        }
        form.reset({pristine: false});
      });
    }else {
      console.log('form invalid');
    }
    
  }

}
