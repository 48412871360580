import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from "@angular/common/http";
// import { routing, appRoutingProviders } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MaterialModule } from './material.module';
import { HomeComponent } from './components/home/home.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { GeneralComponent } from './components/general/general.component';
import { RequirementsComponent } from './components/requirements/requirements.component';
import { CountriesComponent } from './components/countries/countries.component';
import { PresentComponent } from './components/present/present.component';
import { QuestionsComponent } from './components/questions/questions.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { FormHomeComponent } from './components/shared/form-home/form-home.component';
import { FormUpdateComponent } from './components/shared/form-update/form-update.component';
import { PhonePipe } from './pipes/phone.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    GeneralComponent,
    RequirementsComponent,
    CountriesComponent,
    PresentComponent,
    QuestionsComponent,
    FooterComponent,
    FormHomeComponent,
    FormUpdateComponent,
    PhonePipe,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
    // routing
  ],
  providers: [
    // appRoutingProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
