import { NgModule, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { GeneralComponent } from './components/general/general.component';
import { RequirementsComponent } from './components/requirements/requirements.component';
import { CountriesComponent } from './components/countries/countries.component';
import { PresentComponent } from './components/present/present.component';
import { QuestionsComponent } from './components/questions/questions.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'precio-etias', component: GeneralComponent },
  { path: 'tramite-etias-latinoamerica', component: RequirementsComponent },
  { path: 'países-etias', component: CountriesComponent },
  { path: 'menores-de-edad-etias', component: PresentComponent },
  { path: 'preguntas-frecuentes', component: QuestionsComponent },
  { path: '**', pathMatch: 'full', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,  {
    anchorScrolling: 'enabled',	
    onSameUrlNavigation: 'reload',
  	scrollPositionRestoration: 'top',
  	
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

// export const appRoutingProviders: any[] = [];
// export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
// 	onSameUrlNavigation: 'reload',
//   	scrollPositionRestoration: 'enabled',
//   	anchorScrolling: 'enabled',
// });